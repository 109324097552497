export default {
  schedule: {},
  scheduleList: [],
  round: {
    active: {
      eventId: 149702,
      eventIdToday: 372,
      odds: {
        color: [],
        column: [],
        field: [],
        no_goal: [],
        odd_even: [],
        row: [],
        side: [],
      },
      time: '01:12',
    },
    selected: {
      eventId: 149702,
      eventIdToday: 372,
      odds: {
        field: [],
        column: [],
        row: [],
        color: [],
        odd_even: [],
        side: [],
        no_goal: [],
      },
      time: '01:28',
    },
    phase: 'state',
    disabled: false,
  },
  statistics: {
    field: {
      cold: [
        {
          label: 1,
          value: 1,
        },
        {
          label: 2,
          value: 1,
        },
        {
          label: 3,
          value: 1,
        },
        {
          label: 4,
          value: 1,
        },
        {
          label: 6,
          value: 1,
        },
      ],
      hot: [
        {
          label: 1,
          value: 1,
        },
        {
          label: 2,
          value: 1,
        },
        {
          label: 2,
          value: 1,
        },
        {
          label: 4,
          value: 1,
        },
        {
          label: 5,
          value: 1,
        },
      ],
    },
    color: [
      {
        label: 1,
        value: '0',
      },
      {
        label: 2,
        value: '0',
      },
    ],
    column: [
      {
        label: 1,
        value: '0',
      },
      {
        label: 2,
        value: '0',
      },
      {
        label: 3,
        value: '0',
      },
      {
        label: 4,
        value: '0',
      },
      {
        label: 5,
        value: '0',
      },
      {
        label: 6,
        value: '0',
      },
      {
        label: 7,
        value: '0',
      },
      {
        label: 8,
        value: '0',
      },
    ],
    row: [
      {
        label: 1,
        value: '0',
      },
      {
        label: 2,
        value: '0',
      },
    ],
    odd_even: [
      {
        label: 1,
        value: '0',
      },
      {
        label: 2,
        value: '0',
      },
    ],
    side: [
      {
        label: 1,
        value: '0',
      },
      {
        label: 2,
        value: '0',
      },
    ],
    no_goal: [
      {
        label: 1,
        value: '0',
      },
      {
        label: 2,
        value: '0',
      },
      {
        label: 3,
        value: '0',
      },
    ],
  },
  activeBets: {},
  config: {
    company: '',
    channel: '',
    gateway: {
      id: 'PluginVPS',
      allowedOrigins: '*',
    },
    appLayout: 'A',
    product: {
      bets: {
        1: {
          name: '1/24 field',
          value: true,
          odds: 24,
          id: 1,
          betType: 'Special',
        },
        2: {
          name: 'Field odd or even',
          value: true,
          odds: 2,
          id: 2,
          betType: 'Special',
          outcomes: ['Odd', 'Even'],
        },
        3: {
          name: 'Field color',
          value: true,
          odds: 2,
          id: 3,
          betType: 'Special',
          outcomes: ['Purple', 'Yellow'],
        },
        4: {
          name: 'Goal row',
          value: true,
          odds: 3,
          id: 4,
          betType: 'Special',
        },
        5: {
          name: 'Goal column',
          value: true,
          odds: 8,
          id: 5,
          betType: 'Special',
        },
        6: {
          name: 'Side of goal',
          value: true,
          odds: 2,
          id: 6,
          betType: 'Special',
          outcomes: ['left', 'right'],
        },
        7: {
          name: 'No goal',
          value: true,
          id: 7,
          betType: 'Special',
          outcomes: ['miss', 'save', 'post'],
        },
      },
    },
  },
  servicesApi: 'https://menhir.gb.nsoftcdn.com',
  notification: {
    active: false,
    message: '',
    type: 'info',
  },
  mobile: true,
  theme: 'default',
  language: 'en',
  loaded: false,
  expiredBets: false,
  currentScheduleRound: null,
  currentRound: null,
  nextRoundTime: null,
  isonisCountryList: ['MNE'],
  userUuid: null,
  initSetStateDate: null,
  deviceUuid: null,
  isTerminal: false,
  terminalUserData: null,
  ticketHistoryTerminal: [],
  ticketDetailsData: null,
  betslipLockMessage: '',
  inProgressRound: '',
  isUserLoggedIn: false,
  terminalMouseCursorEnabled: true,
  activeTerminalLayout: null,
  allBettingDisabled: false,
  jackpotState: undefined,
  isFreeBetMode: false,
  authChanged: false,
  showFreeBetModal: false,
};
