import Vue from 'vue';
import Vuex from 'vuex';
import { assign, merge } from 'lodash';
import { SdkStore } from '@nsftx/games-sdk-js/';
import storeConfig from './storeConfig';

const store = assign(storeConfig, {
  modules: SdkStore.modules,
});

Vue.use(Vuex);
export default new Vuex.Store(merge({}, store, SdkStore));
