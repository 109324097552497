import types from './mutationTypes';


export default {
  [types.SET_CONFIG](state, data) {
    state.config.user = data.user;
    state.config.platformConfig = data.platformConfig;
    state.config.messageProtocol = data.messageProtocol;
    state.config.applicationName = data.applicationName;
    state.config.locale = data.locale;
    state.config.tenantId = data.tenantId;
    state.config.productId = data.productId;
    state.config.productName = data.productName;
    state.config.productFqn = data.productFqn;
    state.config.platformName = data.platformName;
    state.config.environment = data.environment;
    state.config.company = data.company;
    state.config.channel = data.channel;
    state.config.paymentStrategy = data.paymentStrategy;
    state.config.channelId = data.channelId;
    state.config.origin = data.origin;
    state.config.ui = data.ui;
    state.config.isIntegration = data.isIntegration;
    state.mobile = data.mob;
    state.config.currency = data.currency;
    state.language = data.lang;
    state.config.taxAuthority = data.taxAuthority;
    state.config.platformDataCenter = data.platformDataCenter;
  },

  [types.SET_PRODUCT_CONFIG](state, data) {
    state.config.product.rules = data.rules;
    state.config.product.taxes = data.taxes;
    state.config.product.bets = data.bets;
    state.config.product.odds = data.odds;
  },
  [types.SET_SERVICES_API](state, data) {
    state.servicesApi = data;
  },
  [types.SET_ACTIVE_ROUND](state, data) {
    state.round.active = state.schedule[data] || data;
  },

  [types.SET_SELECTED_ROUND](state, data) {
    state.round.selected = state.schedule[data] || data;
  },

  [types.SET_ROUND_PHASE](state, data) {
    state.round.phase = data;
  },

  [types.SET_SCHEDULE](state, data) {
    state.schedule = data.schedule;
    state.scheduleList = data.list;
  },

  [types.SET_SCHEDULE_EVENT](state, data) {
    state.schedule[data.eventId] = data;
    state.scheduleList.push(data.eventId.toString());
  },

  [types.REMOVE_SCHEDULE_EVENT](state, data) {
    delete state.schedule[data];
    state.scheduleList.splice(state.scheduleList.indexOf(data.toString()), 1);
  },

  [types.SET_STATISTICS](state, data) {
    state.statistics.field = data.field;
    state.statistics.color = data.fieldColor;
    state.statistics.odd_even = data.fieldOddEven;
    state.statistics.column = data.goalColumn;
    state.statistics.row = data.goalRow;
    state.statistics.side = data.goalSide;
    state.statistics.no_goal = data.noGoal;
  },
  [types.SET_NOGOAL_ODDS](state, data) {
    state.config.product.bets[7].odds = data;
  },
  [types.DISABLE_BETTING](state, data) {
    state.round.disabled = data;
  },

  [types.ACTIVATE_BET](state, data) {
    state.schedule[data.eventId].odds[data.type][data.value].active = true;
  },

  [types.ADD_ACTIVE_BET](state, data) {
    state.activeBets[`${data.eventId}-${data.type}-${data.value}`] = data;
  },

  [types.DEACTIVATE_BET](state, data) {
    state.schedule[data.eventId].odds[data.type][data.value].active = false;
  },

  [types.REMOVE_ACTIVE_BET](state, data) {
    delete state.activeBets[`${data.eventId}-${data.type}-${data.value}`];
  },
  [types.SYNC_BET](state, data) {
    state.schedule[data.eventId].odds[data.type][data.value] = data;
  },
  [types.ACTIVATE_NOTIFICATION](state, data) {
    state.notification.active = true;
    state.notification.message = data.message;
    state.notification.type = data.type;
  },
  [types.DEACTIVATE_NOTIFICATION](state) {
    state.notification.active = false;
    state.notification.message = '';
  },
  [types.SET_TRANSLATIONS](state, data) {
    state.translations = data;
  },
  [types.SET_LOADED](state, data) {
    state.loaded = data;
  },
  [types.SET_THEME](state, data) {
    state.theme = data === 'white' ? 'light' : data;
  },
  [types.SET_PAYIN_PROGRESS](state, payinInProgress) {
    state.isPayinInProgress = payinInProgress;
    state.betslip.ticketPayinInProgress = payinInProgress;
  },
  [types.SET_CURRENT_ROUND](state, round) {
    state.currentRound = round;
  },
  [types.SET_CURRENT_SCHEDULE_ROUND](state, round) {
    state.currentScheduleRound = round;
  },
  [types.SET_EXPIRED_BETS](state, value) {
    state.expiredBets = value;
  },
  [types.SET_NEXT_ROUND_TIME](state, time) {
    state.nextRoundTime = time;
  },
  [types.SET_USER_UUID](state, userUuid) {
    state.userUuid = userUuid;
  },
  [types.SET_INIT_SET_STATE_DATE](state, date) {
    state.initSetStateDate = date;
  },
  [types.SET_TERMINAL](state, payload) {
    state.isTerminal = payload;
  },
  [types.SET_TERMINAL_USER_DATA](state, payload) {
    state.terminalUserData = payload;
  },
  [types.SET_TICKET_HISTORY_TERMINAL](state, payload) {
    state.ticketHistoryTerminal = payload.message;
  },
  [types.SET_TICKET_DETAILS_DATA](state, payload) {
    state.ticketDetailsData = payload;
  },
  [types.SET_BETSLIP_LOCK_MESSAGE](state, payload) {
    state.betslipLockMessage = payload;
  },
  [types.SET_IN_PROGRESS_ROUND](state, payload) {
    state.inProgressRound = payload;
  },
  [types.SET_USER_LOGGED_IN](state, payload) {
    state.isUserLoggedIn = payload;
  },
  [types.SET_TERMINAL_MOUSE_CURSOR_ENABLED](state, isEnabled) {
    state.terminalMouseCursorEnabled = isEnabled;
  },
  [types.SET_ACTIVE_TERMINAL_LAYOUT](state, layout) {
    state.activeTerminalLayout = layout;
  },
  [types.DISABLE_ALL_BETTING](state, isDisabled) {
    state.allBettingDisabled = isDisabled;
  },
  [types.SET_JACKPOT_STATE](state, jackpotState) {
    state.jackpotState = jackpotState;
  },
  [types.SET_AUTH_CHANGED](state, isChanged) {
    state.authChanged = isChanged;
  },
  [types.TOGGLE_FREE_BET_MODAL](state, show) {
    state.showFreeBetModal = show;
  },
};
