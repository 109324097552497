import {
  merge,
  omit,
  map,
} from 'lodash';
import { auth } from '@nsftx/games-sdk-js';
import api from '../api/index';
import mutationTypes from './mutationTypes';

export default {
  set_config({ commit }, data) {
    commit(mutationTypes.SET_CONFIG, data);
  },
  set_product_config({ commit }, data) {
    commit(mutationTypes.SET_PRODUCT_CONFIG, data);
  },
  set_services_api({ commit }, data) {
    commit(mutationTypes.SET_SERVICES_API, data);
  },
  set_active_round({ commit }, data) {
    commit(mutationTypes.SET_ACTIVE_ROUND, data);
  },
  set_selected_round({ commit }, data) {
    commit(mutationTypes.SET_SELECTED_ROUND, data);
  },
  set_round_phase({ commit }, data) {
    commit(mutationTypes.SET_ROUND_PHASE, data);
  },
  set_schedule({ commit }, data) {
    commit(mutationTypes.SET_SCHEDULE, data);
  },
  set_schedule_event({ commit }, data) {
    commit(mutationTypes.SET_SCHEDULE_EVENT, data);
  },
  remove_schedule_event({ commit }, data) {
    commit(mutationTypes.REMOVE_SCHEDULE_EVENT, data);
  },
  update_schedule({ commit }, data) {
    commit(mutationTypes.UPDATE_SCHEDULE, data);
  },
  set_statistics({ commit }, data) {
    commit(mutationTypes.SET_STATISTICS, data);
  },
  set_nogoal_odds({ commit }, data) {
    commit(mutationTypes.SET_NOGOAL_ODDS, data);
  },
  disable_betting({ commit }, data) {
    commit(mutationTypes.DISABLE_BETTING, data);
  },
  activate_bet({ commit }, data) {
    commit(mutationTypes.ADD_ACTIVE_BET, data);
    commit(mutationTypes.ACTIVATE_BET, data);
  },
  deactivate_bet({ commit }, data) {
    commit(mutationTypes.DEACTIVATE_BET, data);
    commit(mutationTypes.REMOVE_ACTIVE_BET, data);
  },
  remove_active_bet({ commit }, data) {
    commit(mutationTypes.REMOVE_ACTIVE_BET, data);
  },
  sync_bet({ commit }, data) {
    commit(mutationTypes.SYNC_BET, data);
  },
  activate_notification({ commit }, data) {
    commit(mutationTypes.ACTIVATE_NOTIFICATION, data);
  },
  deactivate_notification({ commit }) {
    commit(mutationTypes.DEACTIVATE_NOTIFICATION);
  },
  set_translations({ commit }, data) {
    commit(mutationTypes.SET_TRANSLATIONS, data);
  },
  set_loaded({ commit }, data) {
    commit(mutationTypes.SET_LOADED, data);
  },
  set_theme({ commit }, data) {
    commit(mutationTypes.SET_THEME, data);
  },
  setUserData({ dispatch }, payload) {
    let user = {};
    if (payload.auth) {
      user = {
        auth: omit(payload.auth, 'user'),
        profile: payload.auth.user,
      };
    } else {
      user = {
        auth: {
          token: payload.token,
          tpToken: payload.tpToken,
        },
        profile: omit(payload, ['token', 'tokenType']),
      };
    }
    dispatch('user/updateUser', user, { root: true });
  },
  setUserBalance({ dispatch }, payload) {
    dispatch('user/setUserBalance', payload, { root: true });
  },
  formatPlayerTickets(state, payload) {
    const getValue = (bet) => {
      let betValue = '';
      if (bet.type === 3 || bet.type === 2 || bet.type === 6 || bet.type === 7) {
        betValue = bet.outcome.title;
      } else {
        betValue = `${bet.typeValue} ${bet.value}`;
      }
      return betValue;
    };
    return map(payload, (tickets) => {
      let bets = [];
      bets = map(tickets.bets, bet => ({
        id: bet.id,
        status: bet.status,
        market: bet.typeValue,
        round: bet.eventId,
        odd: bet.odd.toFixed(2),
        outcome: getValue(bet),
        stake: bet.amount,
        eventValue: bet.eventValue,
        winnings: bet.winnings,
      }));
      return {
        id: tickets.id,
        payout: tickets.payout,
        payin: tickets.payin,
        payinTax: tickets.payinTax,
        payoutTax: tickets.payoutTax,
        superBonus: tickets.superBonus,
        createdAt: tickets.createdAt,
        status: tickets.status,
        maxPossibleWin: tickets.maxPossibleWin,
        bets,
      };
    });
  },
  setPayinProgress({ commit }, payinInProgress) {
    commit(mutationTypes.SET_PAYIN_PROGRESS, payinInProgress);
  },
  setCurrentRound({ commit }, round) {
    commit(mutationTypes.SET_CURRENT_ROUND, round);
  },
  setCurrentScheduleRound({ commit }, round) {
    commit(mutationTypes.SET_CURRENT_SCHEDULE_ROUND, round);
  },
  setExpiredBets({ commit }, value) {
    commit(mutationTypes.SET_EXPIRED_BETS, value);
  },
  setNextRoundTime({ commit }, time) {
    commit(mutationTypes.SET_NEXT_ROUND_TIME, time);
  },
  setUserUuid({ commit }, userUuid) {
    commit(mutationTypes.SET_USER_UUID, userUuid);
  },
  setInitSetStateDate({ commit }, date) {
    commit(mutationTypes.SET_INIT_SET_STATE_DATE, date);
  },
  setTerminal({ commit }, payload) {
    commit(mutationTypes.SET_TERMINAL, payload);
  },
  setTerminalUserData({ commit }, payload) {
    commit(mutationTypes.SET_TERMINAL_USER_DATA, payload);
  },
  setTicketHistoryTerminal({ commit }, payload) {
    commit(mutationTypes.SET_TICKET_HISTORY_TERMINAL, payload);
  },
  setTicketDetailsData({ commit }, payload) {
    commit(mutationTypes.SET_TICKET_DETAILS_DATA, payload);
  },
  setBetslipLockMessage({ commit }, payload) {
    commit(mutationTypes.SET_BETSLIP_LOCK_MESSAGE, payload);
  },
  setInProgressRound({ commit }, payload) {
    commit(mutationTypes.SET_IN_PROGRESS_ROUND, payload);
  },
  setUserLoggedIn({ commit }, payload) {
    commit(mutationTypes.SET_USER_LOGGED_IN, payload);
  },
  setTerminalMouseCursorEnabled({ commit }, isEnabled) {
    commit(mutationTypes.SET_TERMINAL_MOUSE_CURSOR_ENABLED, isEnabled);
  },
  setActiveTerminalLayout({ commit }, layout) {
    commit(mutationTypes.SET_ACTIVE_TERMINAL_LAYOUT, layout);
  },
  disableAllBetting({ commit }, isDisabled) {
    commit(mutationTypes.DISABLE_ALL_BETTING, isDisabled);
  },
  setAuthChanged({ commit }, isChanged) {
    commit(mutationTypes.SET_AUTH_CHANGED, isChanged);
  },
  async getPlayer({ getters, dispatch }, { config, token }) {
    try {
      const player = await auth.authenticate(config, token);
      const isLoggedIn = !!player.uuid;
      const userData = {
        auth: {
          token: player.auth.token,
          tpToken: player.auth.thirdPartyToken || null,
        },
        profile: merge({
          balance: player.balance,
          balanceTotal: player.balance,
          logged: isLoggedIn,
          id: player.uuid,
        }),
      };
      if (isLoggedIn) {
        dispatch('user/updateUser', userData);
        dispatch('setUserBalance', player.balance);
      }
    } catch (error) {
      const message = {
        message: getters.translations.general_player_login,
        notificationTimeout: 7,
      };
      dispatch('notifications/setNotification', message);
    }
  },
  async setJackpotState({ commit, getters }) {
    const jackpot = await api.getJackpot(getters.config);
    if (jackpot.jackpotId) {
      commit(mutationTypes.SET_JACKPOT_STATE, jackpot);
    }
  },
  toggleFreeBetModal({ commit }, show) {
    commit(mutationTypes.TOGGLE_FREE_BET_MODAL, show);
  },
};
