export default {
  SET_PRODUCT_CONFIG: 'SET_PRODUCT_CONFIG',
  SET_CONFIG: 'SET_CONFIG',
  SET_SERVICES_API: 'SET_SERVICES_API',
  SET_ACTIVE_ROUND: 'SET_ACTIVE_ROUND',
  SET_SELECTED_ROUND: 'SET_SELECTED_ROUND',
  SET_ROUND_PHASE: 'SET_ROUND_PHASE',
  SET_SCHEDULE: 'SET_SCHEDULE',
  SET_STATISTICS: 'SET_STATISTICS',
  SET_NOGOAL_ODDS: 'SET_NOGOAL_ODDS',
  UPDATE_SCHEDULE: 'UPDATE_SCHEDULE',
  SET_SCHEDULE_EVENT: 'SET_SCHEDULE_EVENT',
  REMOVE_SCHEDULE_EVENT: 'REMOVE_SCHEDULE_EVENT',
  DISABLE_BETTING: 'DISABLE_BETTING',
  ACTIVATE_BET: 'ACTIVATE_BET',
  DEACTIVATE_BET: 'DEACTIVATE_BET',
  ADD_ACTIVE_BET: 'ADD_ACTIVE_BET',
  REMOVE_ACTIVE_BET: 'REMOVE_ACTIVE_BET',
  RESET_ACTIVE_BETS: 'RESET_ACTIVE_BETS',
  ACTIVATE_NOTIFICATION: 'ACTIVATE_NOTIFICATION',
  DEACTIVATE_NOTIFICATION: 'DEACTIVATE_NOTIFICATION',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  SET_LOADED: 'SET_LOADED',
  SET_THEME: 'SET_THEME',
  SYNC_BET: 'SYNC_BET',
  SET_PAYIN_PROGRESS: 'SET_PAYIN_PROGRESS',
  SET_CURRENT_ROUND: 'SET_CURRENT_ROUND',
  SET_EXPIRED_BETS: 'SET_EXPIRED_ROUNDS',
  SET_CURRENT_SCHEDULE_ROUND: 'SET_CURRENT_SCHEDULE_ROUND',
  SET_NEXT_ROUND_TIME: 'SET_NEXT_ROUND_TIME',
  SET_USER_UUID: 'SET_USER_UUID',
  SET_INIT_SET_STATE_DATE: 'SET_INIT_SET_STATE_DATE',
  SET_TERMINAL: 'SET_TERMINAL',
  SET_TERMINAL_USER_DATA: 'SET_TERMINAL_USER_DATA',
  SET_TICKET_HISTORY_TERMINAL: 'SET_TICKET_HISTORY_TERMINAL',
  SET_TICKET_DETAILS_DATA: 'SET_TICKET_DETAILS_DATA',
  SET_BETSLIP_LOCK_MESSAGE: 'SET_BETSLIP_LOCK_MESSAGE',
  SET_IN_PROGRESS_ROUND: 'SET_IN_PROGRESS_ROUND',
  SET_USER_LOGGED_IN: 'SET_USER_LOGGED_IN',
  SET_TERMINAL_MOUSE_CURSOR_ENABLED: 'SET_TERMINAL_MOUSE_CURSOR_ENABLED',
  SET_ACTIVE_TERMINAL_LAYOUT: 'SET_ACTIVE_TERMINAL_LAYOUT',
  DISABLE_ALL_BETTING: 'DISABLE_ALL_BETTING',
  SET_JACKPOT_STATE: 'SET_JACKPOT_STATE',
  SET_AUTH_CHANGED: 'SET_AUTH_CHANGED',
  TOGGLE_FREE_BET_MODAL: 'TOGGLE_FREE_BET_MODAL',
};
