<template>
  <div class="ticket-history-wrapper" :class="{ tablet: isTablet }">
    <Modal :fullScreen="isMob"
           :showFooter="true"
           class="flex history"
           width="800px"
           height="500px"
           v-if="showModal"
           @closeModal="closeDetails"
           @closeModalOutside="closeDetails('outside')"
           ref="modal">
      <template #header>
        <div class="flex-center">
          <span> {{ productName }} </span>
          <i class="icon icon-close-a"
             @click="closeDetails" />
        </div>
      </template>
      <template #body>
        <TicketsHistory :locale="config.locale" />
      </template>
    </Modal>
  </div>
</template>

<script>
import isMobile from 'ismobilejs';
import { Modal, TicketsHistory, eventBus } from '@nsftx/games-sdk-js';
import { mapGetters } from 'vuex';

export default {
  name: 'TicketHistory',
  components: {
    Modal,
    TicketsHistory,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'config',
      'translations',
    ]),
    isMob() {
      return window.innerWidth < 660;
    },
    productName() {
      return this.translations.pages_player_history_virtual_penalty_shootout;
    },
    isTablet() {
      return isMobile().tablet;
    },
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    closeDetails(option) {
      if (option === 'outside') {
        this.showModal = false;
      } else {
        this.$refs.modal.closeModal('outside');
        setTimeout(() => {
          this.showModal = false;
        }, 500);
      }
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
  mounted() {
    eventBus.$on('openTicketHistory', this.openModal);
    this.$root.$on('closeTicketHistory', this.closeModal);
  },
  beforeDestroy() {
    eventBus.$off('openTicketHistory', this.openModal);
    this.$root.$off('closeTicketHistory', this.closeModal);
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0 !important;
}
.ticket-history-wrapper {
  &.tablet {
    ::v-deep .history {
      .modal {
        height: 500px;
      }
    }
    ::v-deep .ticket-details-modal {
      .modal {
        height: unset;
      }
    }
    ::v-deep .ticket-details-modal {
      .modal {
        height: unset;
      }
    }
    ::v-deep .ticket-details-table {
      .ticket-details-body-desktop {
        div {
          div {
            &:first-child {
              flex: 0.7;
            }
          }
          .rows {
            .row {
              div {
                &:first-child {
                  flex: 0.7;
                }
              }
            }
          }
        }
      }
    }
  }
}
.ticket-history-wrapper {
  //  .history {
  //      ::v-deep .modal.show-modal {
  //        //  height: 500px;
  //      }
  //  }
  //  ::v-deep .notification-wrapper {
  //    bottom: 45px;
  //  }
  ::v-deep .modal-back {
    .show-modal {
      .modal-body {
        //  min-height: 425px;
        padding-bottom: 1em;
        .tickets-history {
          min-height: 410px;
          .notification-wrapper {
            position: absolute;
          }
        }
      }
    }
  }
}
::v-deep .modal-back.ticket-details-modal {
  .modal.show-modal {
    height: unset;
  }
}
.modal-header {
  div {
    text-align: center;
    background-color: var(--background-1);
    span {
      color: var(--text-primary-1);
      font-size: 24px;
      margin-left: auto;
    }
    .icon {
      color: var(--text-primary-1);
      cursor: pointer;
      margin-left: auto;
    }
  }
}
.history {
  ::v-deep .modal {
    background-color: var(--background-1) !important;
  }
}
::v-deep .last-tickets-item {
  .modal {
    background-color: var(--card) !important;
  }
}
::v-deep .input-container {
  height: 18px;
}
::v-deep .tickets-list-wrapper {
  overflow-y: scroll;
  max-height: 310px;
  scrollbar-width: none;
}
::v-deep .tickets-list-wrapper::-webkit-scrollbar {
  width: 0px !important;
}
::v-deep .ticket-details-table::-webkit-scrollbar {
  width: 0px !important;
}
::v-deep .ticket-details-table {
  scrollbar-width: none;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 420px) {
  .modal-header {
    padding-bottom: 0px;
    div {
      span {
        margin-left: 0px;
        font-size: 14px;
      }
    }
  }
  ::v-deep .tickets-list-wrapper {
    max-height: 75vh;
    overflow-y: scroll;
  }
  ::v-deep .ticket-details-modal {
    .modal {
      //  background-color: var(--background-1) !important;
      padding: 0 !important;
      .modal-header {
        padding-bottom: 8px !important;
        padding-left: 16px;
      }
    }
  }
   ::v-deep .ticket-history-item {
    height: unset !important;
  }
}
</style>
