<template>
  <div id="app"
    class="app"
    :class="{ 'hide-cursor': isTerminal && !isTerminalMouseCursorEnabled }"
    :style="getBgImage">
    <TicketHistory />
    <Modal v-if="ticketCanceled"
             showFooter
             class="cancel-ticket-confirm-modal"
             @closeModal="closeCancelTicketModal"
             @closeModalOutside="closeCancelTicketModal('outside')"
             ref="modal">
        <h3 slot="header">
          {{ translations.general_info }}
        </h3>
        <div slot="body">
          {{ ticketCanceledMessage }}
          <div class="modal-actions">
            <button class="button"
                    @click="closeCancelTicketModal">
              {{ translations.general_ok }}
            </button>
          </div>
        </div>
        <div slot="footer">
          <button class="button"
                  @click="closeCancelTicketModal">
            {{ translations.general_ok }}
          </button>
        </div>
      </Modal>
    <Modal v-if="showFreeBetModal"
           class="free-bet-modal"
           :class="[{'mobile': isMob}, {'desktop': isDesktop}]"
           width="344px"
           height="348px"
           showFooter
           @closeModal="claimFreeBets()"
           @closeModalOutside="claimFreeBets('outside')"
           ref="modal">
      <div slot="body" class="body">
        <img src="./assets/free-bet-star.png" alt="">
        <div class="body-message-box">
          <div class="pre-title">{{ 'YOU HAVE WON' }}</div>
          <div class="title">{{ translations.general_free_bet }}</div>
          <div class="subtitle">{{ translations.general_congratulation.toUpperCase() }}</div>
          <span class="subtitle-2">{{ translations.general_claim_ticket }}</span>
        </div>
        <div class="action">
          <button class="button" @click="claimFreeBets">
            {{ claimFreeBetsLabel }}
          </button>
        </div>
      </div>
    </Modal>
    <router-view></router-view>
    <CircleLoader :loader="isTerminal && !loaded"/>
    <LandscapeNotification />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import isMobile from 'ismobilejs';
import {
  merge,
  each,
  assign,
  startCase,
  upperFirst,
} from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import {
  applyPalette,
  Modal,
  CircleLoader,
  gtag,
  eventBus,
  LandscapeNotification,
} from '@nsftx/games-sdk-js';
import { BusService } from '@nsftx/games-bus';
import TicketHistory from '@/components/history/TicketHistory';
import DataService from '@/service/data';
import EventService from '@/service/event';
import store from '@/store';
import NoSleep from 'nosleep.js';

export default {
  name: 'App',
  components: {
    TicketHistory,
    Modal,
    CircleLoader,
    LandscapeNotification,
  },
  computed: {
    ...mapGetters([
      'config',
      'product_config',
      'lang',
      'user/user',
      'user/balance',
      'translations',
      'currentRound',
      'isIsonis',
      'userUuid',
      'deviceUuid',
      'isTerminal',
      'terminalUserData',
      'ticketDetailsData',
      'loaded',
      'isTerminalMouseCursorEnabled',
      'activeTerminalLayout',
      'jackpotState',
      'ajsDisabled',
      'showFreeBetModal',
      'isDesktop',
    ]),
    ...mapGetters({
      isBetslipOpened: 'betslip/isBetslipOpened',
      isUserLoggedIn: 'user/isLoggedIn',
      betslipComponents: 'betslip/betslipComponents',
    }),
    ...mapGetters('gamesBetslip', [
      'ticket',
    ]),
    isMob() {
      return isMobile().any || window.innerWidth <= 660;
    },
    ticketCanceledMessage() {
      return `${this.translations.general_ticket} ${this.canceledTicket.id} ${this.translations.general_successfully_cancelled}!`;
    },
    userProfile() {
      return this['user/user'];
    },
    getTicketDetailsAuth() {
      return btoa(`${this.config.user.id}:${this.config.account.password}`);
    },
    userBalance() {
      return this['user/balance'];
    },
    getBgImage() {
      if (this.config.appLayout === 'TerminalTicketDetails') {
        return 'background-color: var(--background-1);';
      }
      return this.config.ui.dark ? this.bgImage : this.bgImageLight;
    },
    bgImage() {
      return {
        // eslint-disable-next-line
        backgroundImage: `linear-gradient(to bottom, rgba(26, 28, 29, 0), #1A1C1D 88%), url(${require('@/assets/' + this.config.productName + '_dark_bg.jpg')})`,
      };
    },
    bgImageLight() {
      return {
        // eslint-disable-next-line
        backgroundImage: `linear-gradient(to bottom, rgba(26, 28, 29, 0), #FFFFFF 88%), url(${require('@/assets/' + this.config.productName + '_light_bg.jpg')})`,
      };
    },
    getProductName() {
      return this.config.productName.split(/(?=[A-Z])/).join(' ');
    },
    productNameLabel() {
      return this.translations.pages_player_history_virtual_penalty_shootout;
    },
    getTicketHistoryAuthHeaders() {
      const headers = {
        Authorization: `Bearer ${this.terminalUserData.auth.token}`,
        'HTTP-X-NAB-BETSHOP-ID': this.config.betshop.id,
        'HTTP-X-NAB-COMPANY-ID': this.config.companyData.id,
        'HTTP-X-NAB-DEVICE-UUID': this.config.device.deviceUuid,
        'HTTP-X-NAB-DP': 'AdminTerminal',
        'SEVEN-LOCALE': this.config.locale,
      };
      return headers;
    },
    isStandAlone() {
      return this.config.ui.config.mode === 'standalone';
    },
    isGravityGateway() {
      return this.config.messageProtocol.name === 'gravityGateway';
    },
    claimFreeBetsLabel() {
      if (this.isMob) {
        return this.translations.general_claim;
      }
      return this.translations.general_ok;
    },
  },
  data() {
    return {
      busService: null,
      isLoggedIn: false,
      checkTimeout: 10000,
      minimimBetAmout: null,
      ticketCanceled: false,
      canceledTicket: {},
      lastTicketsCalled: false,
      markets: {
        1: { label: 'betting.field_betting', odd: null },
        2: { label: 'betting.odd_or_even', odd: null },
        3: { label: 'betting.purple_or_yellow', odd: null },
        4: { label: 'betting.row_betting', odd: null },
        5: { label: 'betting.column_betting', odd: null },
        6: { label: 'betting.left_or_right', odd: null },
        7: { label: 'betting.no_goal', odd: null },
      },
      range: {
        start: new Date(),
        end: new Date(),
      },
      terminalPayinTicket: null,
      userFreeBets: {
        count: 0,
        betStake: 0,
      },
    };
  },
  methods: {
    ...mapActions([
      'set_config',
      'set_theme',
      'set_product_config',
      'set_translations',
      'set_nogoal_odds',
      'set_loaded',
      'setUserData',
      'setUserBalance',
      'setPayinProgress',
      'setCurrentRound',
      'setExpiredBets',
      'setCurrentScheduleRound',
      'setNextRoundTime',
      'setUserUuid',
      'disable_betting',
      'setInitSetStateDate',
      'setTicketHistoryTerminal',
      'setTicketDetailsData',
      'setBetslipLockMessage',
      'setInProgressRound',
      'setUserLoggedIn',
      'setTerminalUserData',
      'setJackpotState',
      'setAuthChanged',
      'toggleFreeBetModal',
    ]),
    ...mapActions({
      clearBetslip: 'gamesBetslip/clearBetslip',
      setNotification: 'notifications/setNotification',
      setIsPayinButtonDisabled: 'gamesBetslip/setIsPayinButtonDisabled',
      getLastTickets: 'gamesBetslip/getLastTickets',
      setPlayerTickets: 'gamesBetslip/setPlayerTickets',
      setActiveBetslipTab: 'betslip/setActiveBetslipTab',
      enableTicketRebet: 'betslip/enableTicketRebet',
      setScheduleDisabled: 'betslip/setScheduleDisabled',
      lockBetslip: 'betslip/lockBetslip',
      getUserBalance: 'user/getUserBalance',
      setUserFreeBets: 'user/setUserFreeBets',
      getUserFreeBets: 'user/getUserFreeBets',
    }),
    ...mapActions('gamesBetslip', [
      'setIsPayinInProgress',
    ]),
    sendLoadedEvent() {
      if (window.WebAppListener) {
        window.WebAppListener.sendMessage('Slave.Loaded');
      }
    },
    getDateFormat(value, formatType) {
      return moment(value).format(formatType);
    },
    setOddsForTicketPrint(odds) {
      each(odds, (odd) => {
        this.markets[odd.type].odd = odd.value;
      });
    },
    setGtag(device) {
      if (this.config.ui.config.gtm) {
        const { code, auth, preview } = this.config.ui.config.gtm[device];
        gtag.setup(code, auth, preview);
        return;
      }
      gtag.setup();
    },
    getMarketLabel(type) {
      return this.markets[type].label;
    },
    closeCancelTicketModal() {
      this.ticketCanceled = false;
    },
    setTheme(config) {
      const rootElement = document.documentElement;
      const bodyElement = document.body;
      const baseColor = config.ui[config.ui.config.baseColor];
      const theme = config.ui[config.ui.config.theme];

      applyPalette.set(baseColor, rootElement);
      applyPalette.set(theme, bodyElement);
    },
    setUser(user) {
      if (user.user) {
        this.isLoggedIn = !!user.token;
        this.setUserLoggedIn(!!user.token);
        store.dispatch('setUserData', user.user);
        if (this.isLoggedIn) {
          this.setUserUuid(user.user.id);
          this.togglePlayerChannel(this.userProfile.profile.id);
          store.dispatch('user/updateUser', {
            auth: {
              token: user.token,
              tpToken: user.tpToken,
            },
            profile: user.user,
          });
          this.getUserFreeBets();
          //  if (!this.lastTicketsCalled) {
          //    this.getLastTickets();
          //  }
          //  this.lastTicketsCalled = !!user.token;
        }
      }
    },
    togglePlayerChannel(playerId) {
      if (playerId) {
        this.busService.addChannel(`${this.config.productName}.Player`, playerId);
      } else {
        this.busService.removeChannel(`${this.config.productName}.Player`, this.userProfile.profile.id);
      }
    },
    formatTicketBets() {
      return this.ticket.map(n => ({
        payin: Number(n.stake),
        type: n.type,
        value: n.value,
        numEvents: 1,
        eventId: n.eventId,
        market: n.market,
      }));
    },
    clearCheckTimeout() {
      clearTimeout(this.check);
    },
    checkTicket(requestUuid) {
      this.check = setTimeout(() => {
        store.dispatch('gamesBetslip/ticketCheck', requestUuid).then((response) => {
          if (response.data.status.value.toUpperCase() !== 'PENDING') {
            const message = {
              message: this.translations.general_ticket_confirmed,
              status: 'positive',
              notificationTimeout: true,
            };
            if (!this.isTerminal) {
              this.getLastTickets();
            }
            store.dispatch('notifications/clearNotification').then(() => {
              store.dispatch('notifications/setNotification', message);
            });
            this.setIsPayinButtonDisabled(false);
            this.clearBetslip();
            this.setIsPayinInProgress(false);
            store.dispatch('gamesBetslip/setIsPayinInProgress', false);
            store.dispatch('setPayinProgress', false);
            if (this.isTerminal) {
              store.dispatch('betslip/lockBetslip', false);
              store.dispatch('setBetslipLockMessage', '');
              store.dispatch('disable_betting', false);
              this.setIsPayinButtonDisabled(false);
            }
          }
          this.clearCheckTimeout();
        }).catch(() => {
          if (this.isTerminal) {
            store.dispatch('gamesBetslip/setIsPayinInProgress', false);
            this.setIsPayinButtonDisabled(false);
            this.busService.sendMessage({
              action: 'Tickets.Update',
              enforceEvent: true,
              data: {
                action: 'Add',
                ticketData: this.terminalPayinTicket,
                showMessage: false,
              },
            }, true);
            this.busService.sendMessage({
              action: 'Dialog.Show',
              data: {
                message: this.translations.general_ticket_error,
                type: 'warning',
                delay: 3000,
              },
            }, true);
          }
        });
        this.disable_betting(false);
        this.setScheduleDisabled(false);
      }, this.checkTimeout);
    },
    getPrintTemplate() {
      let template = '';
      const messages = this.translations;
      const { currency } = this.config;
      const tempTickets = JSON.parse(JSON.stringify(this.ticket));
      const printBets = merge(tempTickets, this.formatTicketBets());
      template += `${this.getProductName};`;
      template += "{{ ticketDateTimeUTC | date('d.m.Y H:i:s', false) }};";
      template += `${messages.general_pay_in} {{ payin | number_format(2, '.', ',') }}${currency} `;
      template += `${messages.general_payout} {{ payout | number_format(2, '.', ',') }}${currency} `;

      return (
        // eslint-disable-next-line
        template + printBets.map((bet) => {
          let body = '';

          for (let i = 0; i < bet.numEvents; i += 1) {
            body += `${bet.outcome} `;
            body += `${bet.market} `;
            body += `${bet.round} `;
            body += `${bet.odd.toFixed(2)} `;
            body += `${bet.payin} `;
          }
          return body;
        }).join('; ') + '{{ id }}'
      );
    },
    setPageTitle() {
      const { productFqn, applicationName } = this.config;
      document.title = `${startCase(productFqn)}: ${upperFirst(applicationName)}`;
    },
    claimFreeBets() {
      this.setUserFreeBets(this.userFreeBets);
      this.toggleFreeBetModal(false);
    },
  },
  beforeMount() {
    this.busService = new BusService({
      environment: this.config.environment,
      platform: this.config.platformName,
      productName: this.config.productName,
      productId: this.config.productId,
      tenantId: this.config.tenantId,
      locale: this.config.locale,
      applicationName: this.config.applicationName,
      subChannels: [],
      useCommonEventName: true,
      adapters: [
        { name: 'GcmAdapter' },
      ],
      clientMessageProtocol: this.config.messageProtocol,
    });
    const device = this.isMob ? 'mobile' : 'desktop';
    this.busService.start();
    this.setGtag(device);
    this.minimimBetAmout = this.config.product.rules.minBetAmount.value;
    if (!this.ajsDisabled) {
      this.setJackpotState();
      if (this.isTerminal) {
        this.busService.addChannel('Device', this.config.device.uuid);
      }
      if (this.jackpotState) {
        this.busService.addChannel('Jackpot', this.jackpotState.jackpotId);
      }
    }
  },
  mounted() {
    if (isMobile().apple.device) {
      const noSleep = new NoSleep();
      let wakeLockEnabled = false;
      document.addEventListener('click', function enableNoSleep() {
        document.removeEventListener('click', enableNoSleep, false);
        if (!wakeLockEnabled) {
          noSleep.enable();
          wakeLockEnabled = true;
        } else {
          noSleep.disable();
          wakeLockEnabled = false;
        }
      }, false);
    }
    this.sendLoadedEvent();
    this.setTheme(this.config);
    this.enableTicketRebet(false);
    this.setPageTitle();
    window.addEventListener('Bus:Message', (event) => {
      const { eventName } = event.detail;
      switch (eventName) {
        case 'SetState':
          this.setInProgressRound(event.detail.eventIdToday);
          if (event.detail.odds && this.isTerminal) {
            this.setOddsForTicketPrint(event.detail.odds);
          }
          this.setInitSetStateDate(event.detail.offer[0].startDate);
          this.setNextRoundTime(event.detail.offer[0].startsAt);
          this.setCurrentScheduleRound(event.detail.eventIdToday);
          if (event.detail.type === 'countdown') {
            EventService.phaseCountdown(DataService.parseEvent(event.detail));
          }
          if (event.detail.type === 'results') {
            EventService.phaseResults(DataService.parseEvent(event.detail));
          }
          this.set_nogoal_odds(event.detail.odds[event.detail.odds.length - 1].value);
          EventService.state(DataService.parseEvent(event.detail, 'state'));
          this.setCurrentRound(event.detail.eventIdToday);
          this.busService.sendMessage({
            action: 'Slave.Loaded',
          }, true);
          this.set_loaded(true);
          break;
        case 'Tickets.FetchHistory':
          fetch(`${process.env.VUE_APP_TERMINAL_TICKET_HISTORY_PATH}/${this.config.productId}.json?deviceUuid=${this.config.device.deviceUuid}&timeFrom=${event.detail.data.timeFrom}&timeTo=${event.detail.data.timeTo}&count=${event.detail.data.count}`, {
            headers: this.getTicketHistoryAuthHeaders,
          })
            .then(response => response.text())
            .then(text => this.setTicketHistoryTerminal(JSON.parse(text)));
          break;
        case 'StartEvent':
          if (this.isTerminal && this.ticketDetailsData) {
            fetch(`${process.env.VUE_APP_TERMINAL_TICKET_CHECK_PATH}/${this.ticketDetailsData.id}.json`, {
              headers: {
                Authorization: `Basic ${this.getTicketDetailsAuth}`,
                'SEVEN-LOCALE': this.config.locale,
              },
            })
              .then(response => response.text())
              .then(text => this.setTicketDetailsData(JSON.parse(text)));
          }
          this.setInProgressRound(event.detail.eventIdToday);
          this.setCurrentRound(this.currentRound);
          //  this.$root.$emit('updateSchedule');
          EventService.phaseShoot(DataService.parseEvent(event.detail));
          //  this.disable_betting(true);
          this.set_loaded(true);
          break;
        case 'StartCountdown':
          this.setScheduleDisabled(false);
          this.setNextRoundTime(event.detail.offer[0].startsAt);
          this.setCurrentScheduleRound(event.detail.eventIdToday);
          this.setCurrentRound(this.currentRound);
          EventService.phaseCountdown(DataService.parseEvent(event.detail));
          this.$root.$emit('updateSchedule');
          this.set_loaded(true);
          if (!this.isTerminal && this.isLoggedIn) {
            this.getLastTickets();
            if (this.isStandAlone && !this.isGravityGateway) {
              this.getUserBalance();
            }
          }
          if (this.isTerminal && this.ticketDetailsData) {
            fetch(`${process.env.VUE_APP_TERMINAL_TICKET_CHECK_PATH}/${this.ticketDetailsData.id}.json`, {
              headers: {
                Authorization: `Basic ${this.getTicketDetailsAuth}`,
                'SEVEN-LOCALE': this.config.locale,
              },
            })
              .then(response => response.text())
              .then(text => this.setTicketDetailsData(JSON.parse(text)));
          }
          break;
        case 'SetResults':
          EventService.phaseResults(DataService.parseEvent(event.detail));
          this.set_loaded(true);
          if (!this.isTerminal && this.isUserLoggedIn) {
            setTimeout(() => {
              this.getLastTickets();
            }, 2000);
          }
          break;
        case 'TicketUpdate': {
          if (this.isUserLoggedIn && !this.isTerminal) {
            this.getUserFreeBets();
            const message = {
              message: this.translations.general_ticket_confirmed,
              status: 'positive',
              notificationTimeout: true,
            };
            if (event.detail.id) {
              if (event.detail.status.id === '0') {
                this.busService.sendMessage({
                  action: 'Tickets.Resolved',
                  data: {
                    accepted: true,
                    ticket: event.detail,
                  },
                }, true);
              } else if (event.detail.status.id === '1') {
                message.message = this.translations.general_ticket_canceled;
                message.status = 'neutral';
                this.canceledTicket = event.detail;
                this.ticketCanceled = true;
                this.$root.$emit('TicketCancelled');
              }
            }

            (async () => {
              if (event.detail.code) {
                message.message = event.detail.message;
                message.status = 'negative';
                eventBus.$emit('SwitchToBetslipTab');
                store.dispatch('notifications/clearNotification').then(() => {
                  store.dispatch('notifications/setNotification', message);
                });
              } else {
                (async () => {
                  await this.clearBetslip();
                  store.dispatch('notifications/clearNotification').then(() => {
                    store.dispatch('notifications/setNotification', message);
                  });
                })();
              }
              this.getLastTickets();
              store.dispatch('notifications/clearNotification').then(() => {
                store.dispatch('notifications/setNotification', message);
              });
            })();

            this.setIsPayinButtonDisabled(false);
            store.dispatch('gamesBetslip/setIsPayinInProgress', false);
            store.dispatch('setPayinProgress', false);
            this.clearCheckTimeout();
            if (this.isBetslipOpened) {
              store.dispatch('betslip/showBetslip');
            }
            this.disable_betting(false);
            this.setScheduleDisabled(false);
            if (this.isStandAlone && !this.isGravityGateway) {
              this.getUserBalance();
            }
          }
          if (this.isTerminal) {
            const ticket = JSON.parse(JSON.stringify(event.detail));
            each(ticket.bets, (bet) => {
              // eslint-disable-next-line
              bet.market = this.translations[this.getMarketLabel(bet.type)];
              // eslint-disable-next-line
              bet.odd = this.markets[bet.type].odd;
            });
            ticket.ticketPin = event.detail.ticketPin;
            if (event.detail.status.id === '0' && this.activeTerminalLayout === 'VPS') {
              ticket.translation = this.productNameLabel;
              ticket.payinConfirmation = this.translations['print.payin_confirmation'];
              const additionalData = {
                clientPrintJobIdentifier: {
                  id: 'TicketPayIn',
                  uuid: ticket.requestUuid,
                },
              };
              this.busService.sendMessage({
                action: 'Peripherals.Print',
                enforceEvent: true,
                data: {
                  type: 'ticket',
                  productId: 'VirtualPenaltyShootout',
                  data: ticket,
                  additionalData,
                },
              }, true);
              this.busService.sendMessage({
                action: 'Tickets.Update',
                enforceEvent: true,
                data: {
                  action: 'Add',
                  ticketData: ticket,
                  showMessage: false,
                },
              }, true);
              this.busService.sendMessage({
                action: 'Dialog.Show',
                data: {
                  message: this.translations['ticket.add_success'],
                  type: 'success',
                  delay: 3000,
                },
              }, true);
            }
            if (event.detail.status.id === '5' && this.activeTerminalLayout === 'VPSTicketDetails') {
              this.busService.sendMessage({
                action: 'Tickets.Update',
                enforceEvent: true,
                data: {
                  action: 'Payout',
                  ticketData: ticket,
                  showMessage: false,
                },
              }, true);
              if (this.terminalUserData.type === 'OPERATOR') {
                this.busService.sendMessage({
                  action: 'Peripherals.Print',
                  enforceEvent: true,
                  data: {
                    layoutName: 'ticketVirtualPenaltyShootoutPayout',
                    productId: 'VirtualPenaltyShootout',
                    data: ticket,
                  },
                }, true);
                this.busService.sendMessage({
                  action: 'Dialog.Show',
                  data: {
                    message: this.translations['ticket.payout_success']
                      .replace('{{amount}}', event.detail.payout.toFixed(2)),
                    type: 'success',
                    delay: 3000,
                  },
                }, true);
              }
              if (this.terminalUserData.type !== 'OPERATOR') {
                this.busService.sendMessage({
                  action: 'Dialog.Show',
                  data: {
                    message: this.translations['ticket.payout_success_to_balance']
                      .replace('{{amount}}', event.detail.payout.toFixed(2)),
                    type: 'success',
                    delay: 3000,
                  },
                }, true);
              }
              fetch(`${process.env.VUE_APP_TERMINAL_TICKET_CHECK_PATH}/${this.ticketDetailsData.id}.json`, {
                headers: {
                  Authorization: `Basic ${this.getTicketDetailsAuth}`,
                  'SEVEN-LOCALE': this.config.locale,
                },
              })
                .then(response => response.text())
                .then(text => this.setTicketDetailsData(JSON.parse(text)));
            }
            if (event.detail.status.id === '1' && this.activeTerminalLayout === 'VPSTicketDetails') {
              this.busService.sendMessage({
                action: 'Tickets.Update',
                enforceEvent: true,
                data: {
                  action: 'Cancel',
                  ticketData: ticket,
                  showMessage: false,
                },
              }, true);
              this.busService.sendMessage({
                action: 'Dialog.Show',
                data: {
                  message: this.translations['ticket.cancel_success_to_balance']
                    .replace('{{amount}}', event.detail.cancelledAmount.toFixed(2)),
                  type: 'success',
                  delay: 3000,
                },
              }, true);
              this.setTicketDetailsData(ticket);
            }
            if (event.detail.code) {
              this.busService.sendMessage({
                action: 'Dialog.Show',
                data: {
                  message: event.detail.message,
                  type: 'warning',
                  delay: 3000,
                },
              }, true);
              if (event.detail.code === 7000) {
                this.setBetslipLockMessage(event.detail.message);
                //  this.lockBetslip(true);
              }
            }
            this.disable_betting(false);
            this.setScheduleDisabled(false);
            this.setIsPayinButtonDisabled(false);
            this.clearCheckTimeout();
            this.clearBetslip();
            store.dispatch('setPayinProgress', false);
            store.dispatch('gamesBetslip/setIsPayinInProgress', false);
          }
          break;
        }
        case 'User.AuthorizationChanged':
          if (event.detail.data.auth) {
            this.setUser(event.detail.data.auth);
            eventBus.$emit('InitBoost');
          }
          if (event.detail.data.userType === 'OPERATOR' || event.detail.data.userType === 'ANONYMOUS') {
            this.setTerminalUserData(event.detail.data);
          }
          this.$root.$emit('authChange');
          if (this.isUserLoggedIn) {
            this.getLastTickets();
            this.setUserBalance(event.detail.data.auth.user.balance);
          } else {
            this.busService.removeChannel(`${this.config.productName}.Player`, this.userUuid);
            this.setPlayerTickets([]);
            this.$root.$emit('closeTicketHistory');
            this.clearBetslip();
          }
          this.set_loaded(true);
          this.setAuthChanged(true);
          break;
        case 'User.BalanceChanged':
          this.setUserBalance(event.detail.data.balance);
          break;
        case 'Tickets.Checked':
          // eslint-disable-next-line
          let ticketPin = null;
          if (event.detail.data.ticket.ticketPin) {
            // eslint-disable-next-line
            ticketPin = event.detail.data.ticket.ticketPin;
          }
          this.set_loaded(false);
          fetch(`${process.env.VUE_APP_TERMINAL_TICKET_CHECK_PATH}/${event.detail.data.ticket.id}.json`, {
            headers: {
              Authorization: `Basic ${this.getTicketDetailsAuth}`,
              'SEVEN-LOCALE': this.config.locale,
            },
          })
            .then(response => response.text())
            .then((text) => {
              const formated = JSON.parse(text);
              formated.ticketPin = ticketPin;
              this.setTicketDetailsData(formated);
            });
          break;
        case 'FreeBet':
          this.userFreeBets = event.detail.rawData;
          this.toggleFreeBetModal(true);
          break;
        default:
          break;
      }
    });

    eventBus.$on('LoginRequired', () => {
      if (window.WebAppListener) {
        window.WebAppListener.sendMessage('User.LoginRequired');
      }
      this.busService.sendMessage({
        action: 'User.LoginRequired',
      }, true);
    });

    eventBus.$on('BetRemoved', () => {
      const clickEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      window.dispatchEvent(clickEvent);
    });

    eventBus.$on('TicketPayin', () => {
      if (!this.isTerminal) {
        this.disable_betting(true);
        this.setScheduleDisabled(true);
        const additionalInfo = {
          printTemplate: this.getPrintTemplate(),
          timezone: {
            // eslint-disable-next-line
            offset: parseInt(this.userProfile.profile.timezoneOffset),
          },
        };
        store.dispatch('setPayinProgress', true);
        this.setIsPayinButtonDisabled(true);
        store.dispatch('gamesBetslip/setIsPayinInProgress', true);
        store.dispatch('gamesBetslip/payIn',
          {
            payload: this.formatTicketBets,
            additionalInfo: this.isIsonis ? additionalInfo : null,
          }).then((response) => {
          this.checkTicket(response.data.requestUuid);
          const message = {
            message: this.translations.general_ticket_resolving,
            status: 'neutral',
          };
          this.setNotification(message);
        }).catch((error) => {
          if (error) {
            const message = {
              message: this.translations.general_ticket_error,
              status: 'negative',
            };
            this.setNotification(message);
            store.dispatch('gamesBetslip/setIsPayinInProgress', false);
            this.disable_betting(false);
            this.setScheduleDisabled(false);
            this.setIsPayinButtonDisabled(false);
          }
          if (error.response) {
            const errorData = error.response.data;
            console.error(errorData);
            const message = {
              message: error.response.data.message
                ? error.response.data.message : this.translations.general_ticket_error,
              status: 'negative',
            };
            if (error.response.data.code === 11000) {
              message.message = this.translations.general_not_verified_email;
            }
            store.dispatch('gamesBetslip/setIsPayinInProgress', false);
            this.setNotification(message);
          }
          this.setIsPayinButtonDisabled(false);
          return error;
        });
      }
    });

    eventBus.$on('ReturnToLobby', () => {
      if (window.WebAppListener) {
        window.WebAppListener.sendMessage('Navigation.Back');
      }
      this.busService.sendMessage({
        action: 'UI.Show',
        data: {
          name: 'Lobby',
        },
      }, true);
    });

    eventBus.$on('RemoveInvalidBets', () => {
      this.setExpiredBets(false);
      store.dispatch('notifications/clearNotification');
    });

    this.$root.$on('PayoutTicket', (ticket) => {
      // eslint-disable-next-line
      ticket.possiblePayout = ticket.maxPossiblePayout;
      this.busService.sendMessage({
        action: 'Tickets.Payout',
        data: {
          ticket,
        },
      }, true);
    });

    eventBus.$on('LoadLastTickets', () => {
      this.getLastTickets();
    });

    this.$root.$on('PrintTicketCopy', (ticket) => {
      const mappedTicket = assign(ticket, { isCopy: true }, { translation: this.productNameLabel });
      each(mappedTicket.bets, (bet) => {
        // eslint-disable-next-line
        bet.market = this.translations[this.getMarketLabel(bet.type)];
        // eslint-disable-next-line
        bet.odd = this.markets[bet.type].odd;
      });
      this.busService.sendMessage({
        action: 'Peripherals.Print',
        enforceEvent: true,
        data: {
          type: 'ticket',
          productId: 'VirtualPenaltyShootout',
          data: mappedTicket,
        },
      }, true);
    });

    eventBus.$on('TicketPayin', () => {
      if (this.isTerminal) {
        this.disable_betting(true);
        this.setScheduleDisabled(true);
        store.dispatch('setPayinProgress', true);
        store.dispatch('gamesBetslip/setIsPayinInProgress', true);
        this.setIsPayinButtonDisabled(true);
        const additionalInfo = {
          printTemplate: this.getPrintTemplate(),
          timezone: {
            offset: 1,
          },
        };
        store.dispatch('gamesBetslip/payIn',
          {
            payload: this.formatTicketBets,
            additionalInfo: this.isIsonis ? additionalInfo : null,
          }).then((response) => {
          this.busService.clientAdapter.handler.emitAsync({
            action: 'Tickets.Pay',
            data: response,
            async: true,
          }).then((response2) => {
            this.terminalPayinTicket = response2;
            this.checkTicket(response2.ticket.requestUuid);
          });
        });
      }
    });

    eventBus.$on('VpsShootPhase', () => {
      this.$root.$emit('updateSchedule');
      this.disable_betting(false);
    });

    this.$root.$on('TicketCancel', (ticket) => {
      this.busService.sendMessage({
        action: 'Tickets.Cancel',
        data: {
          ticket,
        },
      }, true);
    });

    eventBus.$on('UserBalanceValid', () => {
      if (this.isTerminal) {
        this.busService.sendMessage({
          action: 'Dialog.Close',
          data: {
            id: 'vps_insufficient_balance',
          },
        }, true);
      }
    });

    if (this.config.platformConfig.clientType) {
      if (this.userProfile && this.userProfile?.profile?.id) {
        this.togglePlayerChannel(this.userProfile.profile.id);
        this.getLastTickets();
      }
    }
  },
};
</script>


<style lang="scss">
:root {
  --text-primary-1: #efefef;
  --bg-color-2: #1B1C1D;
  --card-bg: #2c2e30;
  --modal-bg: #1a1c1d;
  --font-size-large: 16px;
  --betslip-bg: rgb(18, 19, 20);
  --loader-wrapper-bg: rgba(18, 19, 20, 0.7);
  --loader-color: var(--text-primary-1);
  --loader-color-active: rgba(239, 239, 239, 0.2);
  --background: hsl(180, 4%, 11%);
  --background-light: hsl(210, 4%, 18%);
  --game-header-background: hsla(225, 5%, 33%, 1);
  --background-3: hsl(210, 5%, 15%);
}
html,
body {
  zoom: 1 !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: var(--text-primary-1);
  //  height: 100%;
  scrollbar-width: none;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
}


*,
*:after,
*:before {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#app {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0c0b0c;
  margin: 0 auto;
  user-select: none;
  background-repeat: no-repeat;
  background-size: cover;
  scrollbar-width: 0 !important;
  ::-webkit-scrollbar {
    display: none;
  }
  &.hide-cursor {
    * {
      cursor: none !important;
    }
  }
  .cancel-ticket-confirm-modal, .free-bet-modal {
    .modal {
      background-color: var(--card);

      .modal-header, .modal-body {
        color: var(--text-primary-1);
      }

      .modal-header {
        font-size: 16px;
        padding: 0 16px;
      }
      .modal-body {
        font-size: 14px;
        .modal-actions {
          display: none;
        }
      }
      .modal-footer {
        padding-right: 43px;
      }
      .button {
        background: transparent;
        border: 0;
        outline: 0;
        font-size: 14px;
        color: #ffc107;
        text-transform: uppercase;
        cursor: pointer;
        font-family: Roboto, 'sans-serif';
        padding: 31px 0 27px 0;
      }
    }
  }

  .free-bet-modal {
    .modal {
      max-height: 348px !important;
      .modal-header { display: none; }
      .modal-body {
        height: 348px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: relative;
        text-align: center;
        .body {
          height: 100%;
          img {
            position: absolute;
            top: -2%;
            left: 0;
            max-width: 100%;
          }
          .body-message-box {
            position: absolute;
            width: 100%;
            top: 66%;
            left: 50%;
            transform: translate(-50%, -50%);

            .title {
              font-size: 42px;
              font-weight: 700;
              color: #90B653;
              margin-bottom: 25px;
            }
            .subtitle { font-size: 18px; font-weight: 700; }
            .subtitle-2 { font-size: 14px; color: var(--text-primary-3); }
          }
        }
      }
      .modal-footer {
        display: none;
      }
    }
    &.desktop {
      .modal {
        .modal-body {
          background-image: none;
          .body {
            img {
              height: 60%;
              left: 50%;
              top: -2%;
              transform: translate(-50%, 0);
            }
            .body-message-box {
              top: 60%;
              .title {
                margin-bottom: 10px;
              }
              .pre-title { display: none; }
            }
            .action {
              position: absolute;
              bottom: 0;
              right: 16px;
            }
          }
        }
      }
    }
    &.mobile {
      .modal {
        max-height: 70% !important;
        .modal-body {
          height: 100% !important;
          background-image: url('assets/free-bet-bg.svg');
          .body {
            .body-message-box {
              .title {
                text-transform: uppercase;
              }
            }
            .action {
              position: absolute;
              left: 0;
              bottom: 20px;
              display: flex;
              width: 100%;
              justify-content: center;
              align-items: center;
              .button {
                min-width: 150px;
                background: #90B653;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
                padding: 0;
                color: var(--text-secondary-1);
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}
.modal-back.cancel-ticket {
  .modal.show-modal {
    padding: 16px !important;
    .modal-header {
      height: unset;
      padding-top: 8px;
      padding-bottom: 24px;
      font-size: 14px;
      h3 {
        margin: 0 !important;
        font-size: 18px !important;
        text-align: left;
      }
    }
    .modal-body {
      font-size: 14px !important;
      text-align: left;
      padding-bottom: 40px;
    }
    .modal-footer {
      padding: 0;
      height: unset;
      padding-bottom: 14px;
    }
  }
  .modal.popup-show-modal {
    padding: 24px !important;
    .modal-header {
      padding-left: 0px !important;
      h3 {
        margin: 0 !important;
        font-size: 18px !important;
      }
    }
    .modal-body {
      font-size: 16px !important;
    }
  }
}

@media all and (max-width: 420px) {
  .modal-back.ticket-details-modal {
    .modal.show-modal-mobile {
      background-color: var(--background-1) !important;
    }
  }
  .modal-back.cancel-ticket {
    padding: 0px;
    .modal.show-modal {
      padding: 24px !important;
      .modal-header {
        h3 {
          margin: 0 !important;
          font-size: 18px !important;
        }
      }
      .modal-body {
        font-size: 16px !important;
      }
    }
    .modal.popup-show-modal {
      padding: 24px !important;
      background-color: var(--card) !important;
      .modal-header {
        padding-left: 0px !important;
        padding-bottom: 0px !important;
        h3 {
          margin: 0 !important;
          font-size: 18px !important;
        }
      }
      .modal-body {
        font-size: 16px !important;
        padding: 8px 0px 0px 0px !important;
      }
      .modal-footer {
        padding: 0px 0px 0px 30px !important;
      }
    }
  }
}

@media (max-width: 640px) {
  .app {
    .cancel-ticket-confirm-modal {
      background-color: var(--overlay-lock);
      .modal {
        background-color: var(--card);
        color: var(--text-primary-1);
        height: 170px !important;

        .modal-header {
          background-color: var(--card);
          padding: 0 24px 16px 24px !important;
        }

        .modal-body {
          position: relative;
          padding: 0 24px;
          color: var(--text-primary-1);
          height: 40px;
          .modal-actions {
            display: block !important;
            position: absolute;
            right: 0px;
            padding-right: 43px;
          }
        }

        .modal-footer {
          display: none;
        }
      }
    }

    .free-bet-modal {
      .modal {
        max-height: 70% !important;
        .modal-body {
          height: 100% !important;
        }
      }
    }
  }
}
</style>
