import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "home" */ '@/pages/Home'),
    },
    /* {
      path: '/grame',
      name: 'grame',
      component: Grame
    },
    {
      path: '/terminal,
      name: 'terminal',
      component: Terminal
    },
    {
      path: '/shop',
      name: 'shop',
      component: Shop
    }
    */
  ],
});
