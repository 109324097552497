var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app",
      class: {
        "hide-cursor": _vm.isTerminal && !_vm.isTerminalMouseCursorEnabled,
      },
      style: _vm.getBgImage,
      attrs: { id: "app" },
    },
    [
      _c("TicketHistory"),
      _vm.ticketCanceled
        ? _c(
            "Modal",
            {
              ref: "modal",
              staticClass: "cancel-ticket-confirm-modal",
              attrs: { showFooter: "" },
              on: {
                closeModal: _vm.closeCancelTicketModal,
                closeModalOutside: function ($event) {
                  return _vm.closeCancelTicketModal("outside")
                },
              },
            },
            [
              _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.translations.general_info) +
                    "\n      "
                ),
              ]),
              _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.ticketCanceledMessage) +
                    "\n        "
                ),
                _c("div", { staticClass: "modal-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      on: { click: _vm.closeCancelTicketModal },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.translations.general_ok) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    on: { click: _vm.closeCancelTicketModal },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.translations.general_ok) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.showFreeBetModal
        ? _c(
            "Modal",
            {
              ref: "modal",
              staticClass: "free-bet-modal",
              class: [{ mobile: _vm.isMob }, { desktop: _vm.isDesktop }],
              attrs: { width: "344px", height: "348px", showFooter: "" },
              on: {
                closeModal: function ($event) {
                  return _vm.claimFreeBets()
                },
                closeModalOutside: function ($event) {
                  return _vm.claimFreeBets("outside")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "body", attrs: { slot: "body" }, slot: "body" },
                [
                  _c("img", {
                    attrs: {
                      src: require("./assets/free-bet-star.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "body-message-box" }, [
                    _c("div", { staticClass: "pre-title" }, [
                      _vm._v(_vm._s("YOU HAVE WON")),
                    ]),
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.translations.general_free_bet)),
                    ]),
                    _c("div", { staticClass: "subtitle" }, [
                      _vm._v(
                        _vm._s(
                          _vm.translations.general_congratulation.toUpperCase()
                        )
                      ),
                    ]),
                    _c("span", { staticClass: "subtitle-2" }, [
                      _vm._v(_vm._s(_vm.translations.general_claim_ticket)),
                    ]),
                  ]),
                  _c("div", { staticClass: "action" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        on: { click: _vm.claimFreeBets },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.claimFreeBetsLabel) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("router-view"),
      _c("CircleLoader", { attrs: { loader: _vm.isTerminal && !_vm.loaded } }),
      _c("LandscapeNotification"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }