import moment from 'moment';
import store from '../store';

const createBetsByType = (data, event) => {
  const betConfig = store.getters.product_config.bets;
  const bets = {};

  for (let i = 1; i <= betConfig[data.type].outcomesLength; i += 1) {
    const bet = {
      active: false,
      type: data.type,
      value: i,
      outcome: i,
      odd: data.value,
      eventId: event.eventId,
      eventIdToday: event.eventIdToday,
      round: event.eventIdToday,
      market: betConfig[data.type].name,
    };

    if (betConfig[data.type].outcomes) {
      bet.outcome = betConfig[data.type].outcomes[i - 1];
    }

    bets[bet.value] = bet;
  }

  return bets;
};

const createBets = (data, event) => {
  const betConfig = store.getters.product_config.bets;
  const bets = {};

  for (let i = 0; i < data.length; i += 1) {
    const betType = data[i].type;
    bets[betConfig[betType].id] = createBetsByType(data[i], {
      eventId: event.eventId,
      eventIdToday: event.eventIdToday,
    });
  }

  return bets;
};

const createSchedule = (data) => {
  const schedule = {};

  for (let i = 0; i < data.length; i += 1) {
    schedule[data[i].eventId] = data[i];
    schedule[data[i].eventId].time = moment(data[i].startsAt).format('HH:mm');
    schedule[data[i].eventId].startDate = data[i].startDate;
    schedule[data[i].eventId].odds = createBets(data[i].odds, {
      eventId: data[i].eventId,
      eventIdToday: data[i].eventIdToday,
    });
  }
  return schedule;
};

export default {
  createSchedule,
  createBets,
};
