import eventBus from './eventBus';

export default {
  init() {
    window.onParentMessage = (event, message) => {
      const modifiedData = JSON.stringify(message);
      const data = JSON.parse(modifiedData);
      eventBus.$emit(message, data);
    };
  },
  isActive() {
    return navigator.userAgent.includes('wv');
  },
};
