import _ from 'lodash';
import { eventBus } from '@nsftx/games-sdk-js';
import store from '../store';

const phaseCountdown = (data) => {
  store.dispatch('disable_betting', false);
  store.dispatch('set_active_round', data.eventId);
  store.dispatch('set_round_phase', 'countdown');
  store.dispatch('set_schedule', { schedule: data.offer, list: Object.keys(data.offer) });

  if (store.getters.selected_round.eventId < data.eventId) {
    store.dispatch('set_selected_round', data.eventId);
  } else {
    store.dispatch('set_selected_round', store.getters.selected_round.eventId);
  }

  const keys = Object.keys(store.getters.active_bets);

  for (let i = 0; i < keys.length; i += 1) {
    if (store.getters.active_bets[keys[i]].eventId < data.eventId) {
      store.dispatch('remove_active_bet', store.getters.active_bets[keys[i]]);
    } else {
      store.dispatch('activate_bet', store.getters.active_bets[keys[i]]);
      store.dispatch('sync_bet', store.getters.active_bets[keys[i]]);
    }
  }
};

const phaseShoot = (data) => {
  delete store.state.schedule[data.eventId];
  store.dispatch('set_round_phase', 'shoot');
  store.dispatch('set_schedule', { schedule: store.state.schedule, list: Object.keys(store.state.schedule) });
  if (store.state.round.active.eventId === store.state.round.selected) {
    store.dispatch('set_active_round', data.eventId + 1);
    store.dispatch('set_selected_round', data.eventId + 1);
  }
  if (store.state.round.active.eventId < store.state.round.selected.eventId) {
    store.dispatch('set_active_round', store.state.round.selected.eventId);

    store.dispatch('set_selected_round', store.state.round.selected.eventId);
  }
  eventBus.$emit('VpsShootPhase');
  const a = _.filter(store.getters['gamesBetslip/ticket'], bet => bet.eventId <= data.eventId);
  _.forEach(a, (bet) => {
    store.dispatch('gamesBetslip/updateBetValue', {
      ...bet,
      locked: true,
      permanentlyLock: true,
    });
    store.dispatch('setExpiredBets', true);
    store.dispatch('notifications/clearNotification').then(() => {
      store.dispatch('notifications/setNotification', {
        message: store.getters.translations.general_invalid_bets_accept,
        status: 'neutral',
        type: store.getters['notifications/notificationTypes'].INVALID_BETS,
      });
    });
  });
  setTimeout(() => {
    if (!store.getters.isTerminal && store.getters.isUserLoggedIn) {
      store.dispatch('gamesBetslip/getLastTickets');
    }
  }, 1000);
};

const phaseResults = (data) => {
  store.dispatch('set_round_phase', 'results');
  store.dispatch('set_statistics', data.statistics);
};

const state = (data) => {
  const parsedData = data;
  store.dispatch('set_round_phase', data.type);
  if (parsedData.type === 'shoot') {
    const offer = [];
    const finalOffer = {};
    const parsedOffer = JSON.parse(JSON.stringify(parsedData.offer));
    _.each(parsedOffer, (round) => {
      if (round.eventId) {
        offer[round.eventId] = round;
      }
    });
    const filtered = offer.filter(el => el != null);
    _.each(filtered, (round) => {
      finalOffer[round.eventId] = round;
    });
    parsedData.offer = finalOffer;
  }
  store.dispatch('set_schedule', { schedule: parsedData.offer, list: Object.keys(parsedData.offer) });

  if (parsedData.type === 'countdown') {
    store.dispatch('set_active_round', parsedData.eventId);
    store.dispatch('set_selected_round', parsedData.eventId);
  } else if (parsedData.type === 'shoot') {
    store.dispatch('set_active_round', parsedData.offer[parsedData.eventId + 1].eventId);
    store.dispatch('set_selected_round', parsedData.offer[parsedData.eventId + 1].eventId);
  } else {
    store.dispatch('set_active_round', {
      eventId: parsedData.eventId,
      eventIdToday: parsedData.eventIdToday,
      startsAt: parsedData.startsAt,
      time: parsedData.time,
    });
    store.dispatch('set_selected_round', data);
  }
  store.dispatch('set_statistics', data.statistics);
};

export default {
  state,
  phaseCountdown,
  phaseResults,
  phaseShoot,
};
