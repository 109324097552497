var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ticket-history-wrapper", class: { tablet: _vm.isTablet } },
    [
      _vm.showModal
        ? _c("Modal", {
            ref: "modal",
            staticClass: "flex history",
            attrs: {
              fullScreen: _vm.isMob,
              showFooter: true,
              width: "800px",
              height: "500px",
            },
            on: {
              closeModal: _vm.closeDetails,
              closeModalOutside: function ($event) {
                return _vm.closeDetails("outside")
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "flex-center" }, [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.productName) + " "),
                        ]),
                        _c("i", {
                          staticClass: "icon icon-close-a",
                          on: { click: _vm.closeDetails },
                        }),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("TicketsHistory", {
                        attrs: { locale: _vm.config.locale },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2684734007
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }