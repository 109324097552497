import _ from 'lodash';
import moment from 'moment';
import store from '../store';
import BettingService from './betting';

// harcoded outcomes until it's added to bets config api
const betMapper = {
  1: {
    name: 'field',
    outcomesLength: 24,
  },
  2: {
    name: 'odd_even',
    outcomesLength: 2,
  },
  3: {
    name: 'color',
    outcomesLength: 2,
  },
  4: {
    name: 'row',
    outcomesLength: 3,
  },
  5: {
    name: 'column',
    outcomesLength: 8,
  },
  6: {
    name: 'side',
    outcomesLength: 2,
  },
  7: {
    name: 'no_goal',
    outcomesLength: 3,
  },
};

const parseBetsConfig = (data) => {
  const bets = {};

  _.forEach(data, (item) => {
    bets[item.id] = {
      id: item.id,
      name: item.name,
      keyname: betMapper[item.id].name,
      odds: item.odds || '0',
      outcomes: item.outcomes || false,
      outcomesLength: betMapper[item.id].outcomesLength,
      active: item.value,
    };
  });

  return bets;
};

const parseConfig = (data) => {
  const config = {};
  config.applicationName = data.applicationName;
  config.environment = data.environment;
  config.productId = data.productId;
  config.channelId = data.channelId;
  config.locale = data.locale;
  config.taxes = data.taxes;
  config.rules = data.rules;
  config.odds = data.odds;
  config.bets = parseBetsConfig(data.bets);

  return config;
};

const parseEvent = (data, state) => {
  const dataParsed = data;
  // eslint-disable-next-line
  const getCurrentRoundTime = (data) => {
    if (dataParsed.offer[data.eventId]) {
      return dataParsed.offer[data.eventId].time;
    }
    return data.time;
  };

  if (state && data.type !== 'shoot') {
    dataParsed.odds = BettingService.createBets(data.odds, {
      eventId: data.eventId,
      eventIdToday: data.eventIdToday,
    });
    dataParsed.time = moment(data.time).format('HH:mm');
    dataParsed.offer[data.eventId] = {
      odds: dataParsed.odds,
      eventIdToday: data.eventIdToday,
      eventId: data.eventId,
      time: getCurrentRoundTime(data),
      startDate: store.getters.initSetStateDate,
    };
  } else if (state && data.type === 'shoot') {
    dataParsed.offer = BettingService.createSchedule(data.offer);
  } else {
    dataParsed.offer = BettingService.createSchedule(data.offer);
  }

  return dataParsed;
};

export default {
  parseConfig,
  parseEvent,
};
